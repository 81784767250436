/*eslint-disable*/
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";

import IndexNavbar from "../../components/Navbars/IndexNavbar.js";
import Footer from "../../components/Footers/Footer.js";
import ContactDetail from "../../components/contactinfo";


export default function InternetSecurity() {
  const [commitHistory, setCommitHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch(
      `${window.$api_url}/api/page/internet-security/`,
      {
        method: "GET",
        headers: {
          'Content-Type': 'application/json'
          
        }
      }
    )
      .then(res => res.json())
      .then(response => {
        // console.log(response);
        setCommitHistory(response);
        setIsLoading(false);
      })
      .catch(error => console.log(error));
  }, []);
  return (
    <>
      <IndexNavbar fixed />
      <div className="container mt-24 pt-2 mx-auto  pb-10">
        <div className="flex flex-wrap gap-8">

          <div className="w-full lg:w-7/12">
          <h1 className="uppercase text-center  sm:text-xl py-1 md:text-2xl  topheading  mb-4">{commitHistory.subtitle}</h1>
            <hr className="bg-red-800  mb-2" />
            <h2 className="text-xl font-bold mb-2">{commitHistory.subtitle2}</h2>
            <img src={`${window.$api_url}${commitHistory.img}`} alt={commitHistory.subtitle} />
            <h2 className="text-2xl font-bold mt-2 mb-2">{commitHistory.title}</h2>
           
            <div className="mt-4 text-justify" dangerouslySetInnerHTML={{ __html: commitHistory.content }}></div>
           

          </div>
          <div className="w-full lg:w-4/12">
            <div className="bg-gray-300">

              <ContactDetail />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
