import './App.css';
// import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Home from "./views/Home";
import About from "./views/About";
import Online from "./views/customized/Online";
import Offline from "./views/customized/Offline";
import Static from "./views/websites/static";
import Contact from "./views/Contact";
import Why from "./views/Why";
import MobileApp from './views/MobileApps';
import SEoServices from "./views/SEoServices";
import Dynamic from "./views/websites/dynamic";
import WebRedesign from "./views/websites/webRedesign";
import WebMaintenance from "./views/websites/webMaintenance";
import QuickFix from './views/other/QuickFix';
import DiagRepair from './views/other/DigRep';
import DataProtection from './views/other/DataProtection';
import InternetSecurity from './views/other/InternetSecurity';
import Virus from './views/other/Virus';

import Privacy from './views/privacy';
import Refund from './views/Refund';
import Disclaimer from './views/Disclaimer';
function App() {
  return (

    <BrowserRouter>
      <Switch>
        {/* add routes with layouts */}
        {/* <Route path="/admin" component={Admin} />
    <Route path="/auth" component={Auth} /> */}
        {/* add routes without layouts */}
        <Route path="/" exact component={Home} />
        <Route path="/about" exact component={About} />

        <Route path="/online-software"  component={Online} />
        <Route path="/offline-software" exact component={Offline} />
        <Route path="/static-website" exact component={Static} />
        <Route path="/why" exact component={Why} />
        <Route path="/contact" exact component={Contact} />
        <Route path="/mobile" exact component={MobileApp} />
        <Route path="/seo" exact component={SEoServices} />
        <Route path="/dynamic-website" exact component={Dynamic} />
        <Route path="/website-redesigning" exact component={WebRedesign} />
        <Route path="/website-maintenance" exact component={WebMaintenance} />
        <Route path="/quick-fix" exact component={QuickFix} />
        <Route path="/data-protection" exact component={DataProtection} />
        <Route path="/diag-repair" exact component={DiagRepair} />
        <Route path="/internet-security" exact component={InternetSecurity} />
        <Route path="/virus-removal" exact component={Virus} />
        <Route path="/privacy" exact component={Privacy} />
        <Route path="/refund" exact component={Refund} />
        <Route path="/disclaimer" exact component={Disclaimer} />
        {/* add redirect for first page */}
        <Redirect from="*" to="/" />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
