/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

import IndexNavbar from "../components/Navbars/IndexNavbar.js";
import Footer from "../components/Footers/Footer.js";
import Enquiry from "../components/Enquiry.jsx";
export default function Contact() {

    return (
        <>
            <IndexNavbar fixed />
            <div className="container mx-auto mt-24 pt-2">
                <div className="flex flex-wrap gap-4 w-full">
                    <div className="w-full lg:w-7/12">


                        <h1 className="uppercase text-center  sm:text-xl py-1 md:text-2xl  topheading  mb-4">Contact us</h1>
                        <h2 className="text-xl sm:text-2xl font-bold mb-2">"We would love to know from you!"</h2>
                        <hr className="bg-red-800 p-0.5 mb-2" />
                        <h2 className="text-xl sm:text-2xl font-bold mb-2">Write Us</h2>
                        <hr className="bg-red-800 p-0.5 mb-2" />
                        <div className="Divcontat">

                            <form name="EnquiryForm" method="post" className="hidden">

                                <div className="mb-2 flex flex-wrap md:flex-nowrap gap-4">
                                    <div className="w-20 font-bold">Name:</div>
                                    <input type="text" id="name" name="name" className="rounded w-full border px-2 py-2" placeholder="Name Please" gtbfieldid="8" />

                                </div>
                                <div className="mb-2 flex flex-wrap md:flex-nowrap gap-4">
                                    <div className="w-20 font-bold">Location:</div>
                                    <input type="text" maxlength="100" id="location" className="box-st" placeholder="Your Location" name="location" gtbfieldid="9" className="rounded w-full border px-2 py-2" />

                                </div>
                                <div className="mb-2 flex flex-wrap md:flex-nowrap gap-4">
                                    <div className="w-20 font-bold">Email: </div>

                                    <input type="text" id="email1" className="rounded w-full border px-2 py-2" name="email1" placeholder="Contact Email Id" gtbfieldid="10" />


                                </div>

                                <div className="mb-2 flex flex-wrap md:flex-nowrap gap-4">
                                    <div className="w-20 font-bold">Phone:</div>
                                    <input type="text" id="phone" className="rounded w-full border px-2 py-2" name="phone" placeholder="Phone or Mobile No." gtbfieldid="11" />
                                </div>
                                <div className="mb-2 flex flex-wrap md:flex-nowrap gap-4">
                                    <div className="w-20 font-bold">Query:</div>
                                    <textarea type="text" id="phone" className="rounded w-full border px-2 py-2" name="query" />
                                </div>




                                <div className="flex gap-4 mt-8">
                                    <td height="45" valign="top">&nbsp;</td>
                                    <td align="center" valign="middle">
                                        <div>  <input name="submit" type="submit" value="Submit" className="bg-black p-2 w-20 rounded text-lg text-white" /> <input name="reset" type="reset" value="Reset" className="bg-black p-2 w-20 rounded text-lg text-white" />

                                        </div>
                                    </td>
                                </div>







                            </form>


                            <Enquiry />

                        </div>

                    </div>

                    <div className="w-full lg:w-4/12">
                        <div className=" bg-gray-200 p-4 mt-1">

                            <div className="bg-yellow-200 py-4 text-center ">



                                <h2 className="text-4xl font-bold mb-1">TEKUNOT</h2>
                                <span className="text-3xl">

                                    info@tekunot.con
                                </span>







                            </div>




                        </div>

                    </div>
                </div>
            </div>


            <Footer />
        </>
    );
}
