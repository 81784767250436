/*eslint-disable*/
import React, { useState, useEffect } from "react";
import IndexNavbar from "../components/Navbars/IndexNavbar";
import Footer from "../components/Footers/Footer.js";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import im1 from "../img/24x7.jpg";
import im2 from "../img/all-solution.jpg";
import im3 from "../img/contact.jpg";
import im4 from "../img/intro.jpg";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import Enquiry from "../components/Enquiry";
export default function Home() {
  const [commitHistory, setCommitHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch(
      `${window.$api_url}/api/other/tekunot-important-links/`,
      {
        method: "GET",
        headers: {
          'Content-Type': 'application/json'

        }
      }
    )
      .then(res => res.json())
      .then(response => {
        // console.log(response);
        setCommitHistory(response);
        setIsLoading(false);
      })
      .catch(error => console.log(error));
  }, []);
  return (
    <>
      <IndexNavbar fixed />

      <div className="container mx-auto  lg:-mt-0 pb-10">
        <div className="mt-28 mb-12 w-full  px-4">

          <Carousel>
            <div>
              <img src={im4} />
              {/* <p className="legend">Legend 3</p> */}
            </div>
            <div>
              <img src={im2} />
            </div>
            <div>
              <img src={im1} />
            </div>
            <div>
              <img src={im3} />
            </div>
          </Carousel>
        </div>
        <div className="flex justify-center flex-wrap gap-8">
          <div>

            <h2 className="text-lg bg-blue-800  font-bold text-white px-1 ">
              {commitHistory.title}
            </h2>

            <div className="text-justify  text-sm" dangerouslySetInnerHTML={{ __html: commitHistory.content }}>
            </div>
          </div>

          <div className=" bg-gray-200 px-4 pt-3">

            <div className="py-4 mt-6 bg-yellow-200 w-80 text-center">



              <h2 className="text-4xl font-bold mb-1">TEKUNOT</h2>
              <span className="text-3xl">

                info@tekunot.con
              </span>







            </div>




          </div>
        </div>


      </div>
      <div className="fixed bottom-10 z-50 right-1">

        <Popup modal trigger={<button className="bg-blue-800 px-4 rounded text-lg text-white transform -rotate-90"> Enquiry</button>} position="left center">
          {close => (
            <div className="bg-gray-300 p-2 ">
              <div className="flex justify-end -mr-4 -mt-4">

              <a className="close bg-red-500 px-2.5 pointer-cursor text-4xl font-bold text-white rounded-full" onClick={close}>
                &times;
              </a>
              </div>

              <Enquiry />

            </div>
          )}
        </Popup>

      </div>



      <Footer />
    </>
  );
}
