/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Enquiry from "./Enquiry";
export default function ContactDetail() {
    // const { loading, error, data } = useQuery(KEYWORDS_QUERY)
    const [commitHistory, setCommitHistory] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetch(
            `${window.$api_url}/api/other/contact-us/`,
            {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json'

                }
            }
        )
            .then(res => res.json())
            .then(response => {
                // console.log(response);
                setCommitHistory(response);
                setIsLoading(false);
            })
            .catch(error => console.log(error));
    }, []);
    return (
        <>
            <div className="p-4">
                <h4 className="font-bold text-2xl mb-4">Quick Contact</h4>

                <form name="EnquiryForm" method="post" className="hidden" >

                    <div className="mb-2 flex flex-wrap md:flex-nowrap gap-4">
                        <div className="w-20 font-bold">Name:</div>
                        <input type="text" id="name" name="name" className="rounded w-full border px-2 py-2" placeholder="Name Please" gtbfieldid="8" />

                    </div>

                    <div className="mb-2 flex flex-wrap md:flex-nowrap gap-4">
                        <div className="w-20 font-bold">Location:</div>
                        <input type="text"  id="location" className="box-st" placeholder="Your Location" name="location" gtbfieldid="9" className="rounded w-full border px-2 py-2" />

                    </div>

                    <div className="mb-2 flex flex-wrap md:flex-nowrap gap-4">
                        <div className="w-20 font-bold">Email: </div>

                        <input type="text" id="email1" className="rounded w-full border px-2 py-2" name="email1" placeholder="Contact Email Id" gtbfieldid="10" />


                    </div>

                    <div className="mb-2 flex flex-wrap md:flex-nowrap gap-4">
                        <div className="w-20 font-bold">Phone:</div>
                        <input type="text" className="rounded w-full border px-2 py-2" name="phone" placeholder="Phone or Mobile No." gtbfieldid="11" />
                    </div>

                    <div className="mb-2 flex flex-wrap md:flex-nowrap gap-4">
                        <div className="w-20 font-bold">Query:</div>
                        <textarea type="text" className="rounded w-full border px-2 py-2" name="query" />
                    </div>


                    <div className="mb-4 flex flex-wrap justify-center gap-2">

                        <input name="submit" type="submit" value="Submit" className="bg-black p-2 text-white rounded text-lg w-20" />
                        <input name="reset" type="reset" value="Reset" className="bg-black p-2 text-white rounded text-lg w-20" />

                    </div>


                </form>


                <Enquiry />


                <div className="bg-white p-2  mt-10 flex justify-center flex-wrap">
                    <img src={`${window.$api_url}${commitHistory.img}`} alt="TEKUNOT" />

                    <div className="mt-4 text-center text-3xl" dangerouslySetInnerHTML={{ __html: commitHistory.content }}></div>


                </div>

            </div >

        </>
    );
}
