import React, { useState, useEffect } from "react";

export default function Footer() {
  const [commitHistory, setCommitHistory] = useState([]);
  // const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch(
      `${window.$api_url}/api/others/`,
      {
        method: "GET",
        headers: {
          'Content-Type': 'application/json'

        }
      }
    )
      .then(res => res.json())
      .then(response => {
        // console.log(response);
        setCommitHistory(response);
        // setIsLoading(false);
      })
      .catch(error => console.log(error));
  }, []);
  return (
    <>
      <footer className=" bg-gray-900 text-white pt-8 ">

        <div className=" container justify-center mx-auto px-4">
          <div className="flex flex-wrap justify-between  text-sm">
            <div className="w-full md:w-1/4 mb-4 md:mb-0">

              {commitHistory.map(item => (
                <div key={item.id}>
                  {item.slug === "about-us" ?
                    <div>
                      <h2 className="text-lg ftitle">{item.title}</h2>
                      <hr className="bg-white w-40"></hr>
                      <div className="mt-4 text-justify" dangerouslySetInnerHTML={{ __html: item.content }}>
                      </div>
                    </div>
                    :
                    ""
                  }
                </div>
              ))}
            </div>

            <div className="w-full md:w-80 mb-4 md:mb-0">

              {commitHistory.map(item => (
                <div key={item.id}>
                  {item.slug === "links" ?
                    <div>
                      <h2 className="text-lg ftitle">{item.title}</h2>
                      <hr className="bg-white w-40"></hr>

                      <div className="mt-4 leading-6" dangerouslySetInnerHTML={{ __html: item.content }}>
                      </div>
                    </div>
                    :
                    ""
                  }
                </div>
              ))}

            </div>
            <div className="w-full md:w-1/4 mb-4 md:mb-0">

              {commitHistory.map(item => (
                <div key={item.id}>
                  {item.slug === "keywords" ?
                    <div>
                      <h2 className="text-lg ftitle">{item.title}</h2>
                      <hr className="bg-white w-40"></hr>

                      <div className="mt-4" dangerouslySetInnerHTML={{ __html: item.content }}>
                      </div>
                    </div>
                    :
                    ""
                  }
                </div>
              ))}
            </div>
            <div className="w-full md:w-1/4 mb-4 md:mb-0">

              {commitHistory.map(item => (
                <div key={item.id}>
                  {item.slug === "contact-us" ?
                    <div>
                      <h2 className="text-lg ftitle">{item.title}</h2>
                      <hr className="bg-white w-40"></hr>
                      <div className="flex justify-center">

                        <img src={`${window.$api_url}${item.img}`} alt="TEKUNOT" />
                      </div>
                      <div className="w-full text-center text-3xl" dangerouslySetInnerHTML={{ __html: item.content }}>
                      </div>
                    </div>
                    :
                    ""
                  }
                </div>
              ))}

            </div>
          </div>

        </div>


        <hr className="mt-2 border-blueGray-300" />
      </footer>
      <div className="flex bg-gray-700 text-white p-2 flex-wrap items-center md:justify-between justify-center">
        <div className="w-full md:w-4/12 px-4 mx-auto text-center">
          <div className="text-sm text-blueGray-500 font-semibold py-1">
            {/* Copyright © {new Date().getFullYear()} TEKUNOT by{" "} */}
            All rights reserved by TEKUNOT

          </div>
        </div>
      </div>
    </>
  );
}
