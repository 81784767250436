
/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// components
import {MdMenu} from "react-icons/md";
import { slide as Menu } from 'react-burger-menu'

export default class Navbar extends React.Component {
  showSettings (event) {
    event.preventDefault();
  }
  state={
    logo:[]

  }
  componentDidMount(){
    fetch(
            `${window.$api_url}/api/other/logo/`,
            {
              method: "GET",
              headers: {
                'Content-Type': 'application/json'
      
              }
            }
          )
            .then(res => res.json())
            .then(response => {
              this.setState({logo:response})
            })

  }

  render () {
    // NOTE: You also need to provide styles, see https://github.com/negomi/react-burger-menu#styling
    return (
      <>
      <div className="lg:hidden flex container justify-between fixed top-0 z-50 bg-white w-full">
      <Link to="/" className="leading-relaxed inline-block   whitespace-nowrap uppercase">
                     <img src={`${window.$api_url}${this.state.logo.img}`} alt="TEKUNOT" />
                   </Link>

      <Menu right noOverlay id={ "sidebar" } className={ "my-menu text-white" }  width={ '240px' }  >
       <li className="flex items-center px-1 -mt-4">

       
      <img src={`${window.$api_url}${this.state.logo.img}`} alt="TEKUNOT" className="" />
       </li>
             <li className="flex items-center px-1  "><a href="/">Home </a>  </li>

             <li className="flex items-center px-1"><a href="/about/">About Us</a></li>
             <li className="flex items-center px-1">
             <div className="tab w-full overflow-hidden">
                <input className="absolute opacity-0" id="tab-single-four" type="radio" name="tabs4" />
                <label className="block leading-normal cursor-pointer" htmlFor="tab-single-four">Customized Software</label>
                <div className="tab-content overflow-hidden bg-gray-700">
                <li className="py-2"><a href="/online-software/">Online Software</a></li>
                      <li className="pb-2"><a href="/offline-software/">Offline Software</a></li>
                </div>
             </div>
             </li>
            

               <li className="flex items-center px-1">
               <div className="tab w-full overflow-hidden">
                <input className="absolute opacity-0" id="tab-single-three" type="radio" name="tabs3" />
                <label className="block leading-normal cursor-pointer" htmlFor="tab-single-three">Professional Websites</label>
                <div className="tab-content overflow-hidden bg-gray-700">
                <li className="py-1"><a href="/static-website/">Static Websites</a></li>
                      <li className="py-1"><a href="/dynamic-website/">Dynamic Websites</a></li>
                      <li className="py-1"><a href="/website-redesigning/">Website Redesigning</a></li>
                      <li className="py-1"><a href="/website-maintenance/">Website Maintainence</a></li>
                </div>
             </div>

          
          </li>
               <li className="flex items-center px-1"> <a href="/mobile/">Mobile Apps </a>  </li>
                  <li className="flex items-center px-1"> <a href="/seo/">SEO Services </a>  </li>
              

            
                  <li className="flex items-center px-1">
                  <div className="tab w-full overflow-hidden">
                <input className="absolute opacity-0" id="tab-single-two" type="radio" name="tabs2" />
                <label className="block leading-normal cursor-pointer" htmlFor="tab-single-two">Other Services</label>
                <div className="tab-content overflow-hidden bg-gray-700">
                <li className="py-1"><a href="/quick-fix/">Quick Fix</a></li>
                      <li className="py-1"><a href="/diag-repair/">Diagnostic &amp; Repair</a></li>
                      <li className="py-1"><a href="/virus-removal/">Virus &amp; Spyware Removal</a></li>
                      <li className="py-1"><a href="/internet-security/">Internet Security</a></li>
                      <li className="py-1"><a href="/data-protection/">Data Protection</a></li>
                </div>
             </div>


             
               </li>

              



               <li className="flex items-center px-1"><a href="/why/">Why TEKUNOT?</a></li>

                <li className="flex items-center px-1"><a href="/contact/">Contact Us</a></li>




      </Menu>
      </div>

<div className="hidden lg:block">

<nav className="hidden lg:block container top-0 pt-3 pb-5 fixed bg-white w-full z-50">
    <Link to="/" className="text-blueGray-700 text-sm  leading-relaxed inline-block h-8  whitespace-nowrap uppercase">
                    <img src={`${window.$api_url}${this.state.logo.img}`} alt="TEKUNOT" className="-mt-6" />
                  </Link>
    </nav>
    <nav className="hidden lg:block top-16 fixed z-50 w-full flex flex-wrap items-center justify-between px-2 py-2 navbar-expand-lg md:bg-gray-800 shadow text-white">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          
          <div
            className={
              "lg:flex flex-grow items-center bg-gray-800 lg:shadow-none"
            }
            id="example-navbar-warning"
          >
           
            <ul className="flex flex-col gap-2 lg:flex-row list-none lg:ml-auto text-sm">
             
               <li className="flex items-center"><a href="/">Home </a>  </li>

               <li className="flex items-center px-1"><a href="/about/">About Us</a></li>

               <li className="dropdown inline px-1 pt-0.5   cursor-pointer  tracking-wide">
                 <a>Customized Software</a>
                 <div className="dropdown-menu absolute hidden h-auto flex pt-1 text-black">
                   <ul className="block w-full  bg-gray-800 text-white shadow px-2 py-2">
                     <li className="py-1"><a href="/online-software/">Online Software</a></li>
                     <li className="py-1"><a href="/offline-software/">Offline Software</a></li>
                   </ul>
                 </div>
               </li>

               <li className="dropdown inline px-1 pt-0.5   cursor-pointer    tracking-wide">
                 <a>Professional Websites</a>
                 <div className="text-black dropdown-menu absolute hidden h-auto flex pt-1">
                   <ul className="block w-full bg-gray-800 text-white shadow px-2 py-2">
                     <li className="py-1"><a href="/static-website/">Static Websites</a></li>
                     <li className="py-1"><a href="/dynamic-website/">Dynamic Websites</a></li>
                     <li className="py-1"><a href="/website-redesigning/">Website Redesigning</a></li>
                     <li className="py-1"><a href="/website-maintenance/">Website Maintainence</a></li>
                   </ul>
                 </div>
               </li>




               <li className="flex items-center px-1"> <a href="/mobile/">Mobile Apps </a>  </li>
               <li className="flex items-center px-1"> <a href="/seo/">SEO Services </a>  </li>


               <li className="dropdown inline px-1 pt-0.5   cursor-pointer    tracking-wide">
                 <a>Other Services</a>
                 <div className="text-black dropdown-menu absolute hidden h-auto flex pt-1">
                   <ul className="block w-full  bg-gray-800 text-white shadow px-2 py-2">
                     <li className="py-1"><a href="/quick-fix/">Quick Fix</a></li>
                     <li className="py-1"><a href="/diag-repair/">Diagnostic &amp; Repair</a></li>
                     <li className="py-1"><a href="/virus-removal/">Virus &amp; Spyware Removal</a></li>
                     <li className="py-1"><a href="/internet-security/">Internet Security</a></li>
                     <li className="py-1"><a href="/data-protection/">Data Protection</a></li>

                   </ul>
                 </div>
               </li>



              <li className="flex items-center px-1"><a href="/why/">Why TEKUNOT?</a></li>

               <li className="flex items-center "><a href="/contact/">Contact Us</a></li>



            


             
            </ul>
          
          
          
          </div>
        </div>
      </nav>

</div>

      </>
    );
  }
}
// export default function Navbar(props) {
//   const [navbarOpen, setNavbarOpen] = React.useState(false);
//   const [commitHistory, setCommitHistory] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);

//   useEffect(() => {
//     fetch(
//       `${window.$api_url}/api/other/logo/`,
//       {
//         method: "GET",
//         headers: {
//           'Content-Type': 'application/json'

//         }
//       }
//     )
//       .then(res => res.json())
//       .then(response => {
//         // console.log(response);
//         setCommitHistory(response);
//         setIsLoading(false);
//       })
//       .catch(error => console.log(error));
//   }, []);
//   return (
//     <>
    // <nav className="hidden lg:block top-0 pt-3 pb-5 fixed bg-white w-full z-50">
    // <Link to="/" className="text-blueGray-700 text-sm  leading-relaxed inline-block h-8  whitespace-nowrap uppercase">
    //                 <img src={`${window.$api_url}${commitHistory.img}`} alt="TEKUNOT" className="-mt-6" />
    //               </Link>
    // </nav>

//     {/* Mobile Nav */}

//     <nav className=" lg:hidden top-0 fixed z-50 w-full flex  pt-4 navbar-expand-lg bg- shadow text-white bg-white h-20">
//         <div className="container px-4 mx-auto flex flex-wrap items-right justify-between">
//         <Link to="/" className="text-blueGray-700 text-sm  leading-relaxed inline-block h-  whitespace-nowrap uppercase">
//                     <img src={`${window.$api_url}${commitHistory.img}`} alt="TEKUNOT" className="-mt-6" />
//                   </Link>
//           <div className="w-full -mt-24  flex justify-end lg:w-auto lg:static lg:block lg:justify-start">
           
                 
                 
//             <button
//               className="text-black cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
//               type="button"
//               onClick={() => setNavbarOpen(!navbarOpen)}
//             >
//               <MdMenu size={35} />
//             </button>
//             </div>




//           <div
//             className={
//               "flex justify-end -mt-2 w-full" +
//               (navbarOpen ? " block" : " hidden")
//             }
//             id="example-navbar-warning"
//           >
           
//             <ul className="flex bg-gray-800 flex-col gap-2 lg:flex-row list-none lg:ml-auto text-sm">
            
            
//             <li className="flex items-center px-1 pt-4"><a href="/">Home </a>  </li>

//             <li className="flex items-center px-1"><a href="/about/">About Us</a></li>
//             <li className="flex items-center px-1">
//             <div className="tab w-full overflow-hidden">
//                <input className="absolute opacity-0" id="tab-single-four" type="radio" name="tabs4" />
//                <label className="block leading-normal cursor-pointer" htmlFor="tab-single-four">Customized Software</label>
//                <div className="tab-content overflow-hidden bg-gray-700">
//                <li className="py-2"><a href="/online-software/">Online Software</a></li>
//                      <li className="pb-2"><a href="/offline-software/">Offline Software</a></li>
//                </div>
//             </div>
//             </li>
            

//               <li className="flex items-center px-1">
//               <div className="tab w-full overflow-hidden">
//                <input className="absolute opacity-0" id="tab-single-three" type="radio" name="tabs3" />
//                <label className="block leading-normal cursor-pointer" htmlFor="tab-single-three">Professional Websites</label>
//                <div className="tab-content overflow-hidden bg-gray-700">
//                <li className="py-1"><a href="/static-website/">Static Websites</a></li>
//                      <li className="py-1"><a href="/dynamic-website/">Dynamic Websites</a></li>
//                      <li className="py-1"><a href="/website-redesigning/">Website Redesigning</a></li>
//                      <li className="py-1"><a href="/website-maintenance/">Website Maintainence</a></li>
//                </div>
//             </div>

          
//          </li>
//               <li className="flex items-center px-1"> <a href="/mobile/">Mobile Apps </a>  </li>
//                  <li className="flex items-center px-1"> <a href="/seo/">SEO Services </a>  </li>
              

             
//                  <li className="flex items-center px-1">
//                  <div className="tab w-full overflow-hidden">
//                <input className="absolute opacity-0" id="tab-single-two" type="radio" name="tabs2" />
//                <label className="block leading-normal cursor-pointer" htmlFor="tab-single-two">Other Services</label>
//                <div className="tab-content overflow-hidden bg-gray-700">
//                <li className="py-1"><a href="/quick-fix/">Quick Fix</a></li>
//                      <li className="py-1"><a href="/diag-repair/">Diagnostic &amp; Repair</a></li>
//                      <li className="py-1"><a href="/virus-removal/">Virus &amp; Spyware Removal</a></li>
//                      <li className="py-1"><a href="/internet-security/">Internet Security</a></li>
//                      <li className="py-1"><a href="/data-protection/">Data Protection</a></li>
//                </div>
//             </div>


             
//               </li>

              



//               <li className="flex items-center px-1"><a href="/why/">Why TEKUNOT?</a></li>

//                <li className="flex items-center px-1"><a href="/contact/">Contact Us</a></li>



            


             
//             </ul>
          
          
          
//           </div>
//         </div>
//       </nav>

//     {/* Mobile Nav End */}




//       <nav className="hidden lg:block top-16 fixed z-50 w-full flex flex-wrap items-center justify-between px-2 py-2 navbar-expand-lg md:bg-gray-800 shadow text-white">
//         <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
//           <div className="w-full relative -mt-20 flex justify-end lg:w-auto lg:static lg:block lg:justify-start">
           
                 
                 
//             <button
//               className="text-black cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
//               type="button"
//               onClick={() => setNavbarOpen(!navbarOpen)}
//             >
// <MdMenu size={35} />
//             </button>
//           </div>
//           <div
//             className={
//               "lg:flex flex-grow items-center bg-gray-800 lg:bg-opacity-0 lg:shadow-none" +
//               (navbarOpen ? " block" : " hidden")
//             }
//             id="example-navbar-warning"
//           >
           
//             <ul className="flex flex-col gap-2 lg:flex-row list-none lg:ml-auto text-sm">
             
//                <li className="flex items-center"><a href="/">Home </a>  </li>

//                <li className="flex items-center px-1"><a href="/about/">About Us</a></li>

//                <li className="dropdown inline px-1 pt-0.5   cursor-pointer  tracking-wide">
//                  <a>Customized Software</a>
//                  <div className="dropdown-menu absolute hidden h-auto flex pt-1 text-black">
//                    <ul className="block w-full  bg-gray-800 text-white shadow px-2 py-2">
//                      <li className="py-1"><a href="/online-software/">Online Software</a></li>
//                      <li className="py-1"><a href="/offline-software/">Offline Software</a></li>
//                    </ul>
//                  </div>
//                </li>

//                <li className="dropdown inline px-1 pt-0.5   cursor-pointer    tracking-wide">
//                  <a>Professional Websites</a>
//                  <div className="text-black dropdown-menu absolute hidden h-auto flex pt-1">
//                    <ul className="block w-full bg-gray-800 text-white shadow px-2 py-2">
//                      <li className="py-1"><a href="/static-website/">Static Websites</a></li>
//                      <li className="py-1"><a href="/dynamic-website/">Dynamic Websites</a></li>
//                      <li className="py-1"><a href="/website-redesigning/">Website Redesigning</a></li>
//                      <li className="py-1"><a href="/website-maintenance/">Website Maintainence</a></li>
//                    </ul>
//                  </div>
//                </li>




//                <li className="flex items-center px-1"> <a href="/mobile/">Mobile Apps </a>  </li>
//                <li className="flex items-center px-1"> <a href="/seo/">SEO Services </a>  </li>


//                <li className="dropdown inline px-1 pt-0.5   cursor-pointer    tracking-wide">
//                  <a>Other Services</a>
//                  <div className="text-black dropdown-menu absolute hidden h-auto flex pt-1">
//                    <ul className="block w-full  bg-gray-800 text-white shadow px-2 py-2">
//                      <li className="py-1"><a href="/quick-fix/">Quick Fix</a></li>
//                      <li className="py-1"><a href="/diag-repair/">Diagnostic &amp; Repair</a></li>
//                      <li className="py-1"><a href="/virus-removal/">Virus &amp; Spyware Removal</a></li>
//                      <li className="py-1"><a href="/internet-security/">Internet Security</a></li>
//                      <li className="py-1"><a href="/data-protection/">Data Protection</a></li>

//                    </ul>
//                  </div>
//                </li>



//               <li className="flex items-center px-1"><a href="/why/">Why TEKUNOT?</a></li>

//                <li className="flex items-center "><a href="/contact/">Contact Us</a></li>



            


             
//             </ul>
          
          
          
//           </div>
//         </div>
//       </nav>
//     </>
//   );
// }
