/*eslint-disable*/
import React from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default class Enquiry extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '', email: '', location: '', query: '', phone: ''
        };
    }

    submit = (e) => {
        e.preventDefault();
        fetch(`${window.$api_url}/api/enquiry-post/`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(this.state)
        }).then(
            data => {
                console.log(data);
                toast.success('Thanks !', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                // window.location.reload();
                // window.location.href = "/response/?res=" + data.statusText;

            }
        ).catch(error => {
            console.error(error);
            toast.error('Please Contact to Admin !', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        )

    }
    inputChanged = (e) => {
        this.setState({ [e.target.name]: e.target.value });

        // const val = this.state.formvalue;
        // val[event.target.name] = event.target.value;
        // this.setState({ formvalue: val });
    }
    render() {

        return (
            <>
                <div>


                    <div className="mb-2 flex flex-wrap md:flex-nowrap gap-4">
                        <div className="w-24 font-bold -mb-4">Name:</div>
                        <input type="text" name="name" className="rounded w-full border px-2 py-2" placeholder="Name Please" onChange={this.inputChanged} />

                    </div>
                    <div className="mb-2 flex flex-wrap md:flex-nowrap  gap-4">
                        <div className="w-24 font-bold -mb-4">Location:</div>
                        <input type="text" className="box-st" placeholder="Your Location" name="location" className="rounded w-full border px-2 py-2" onChange={this.inputChanged} />

                    </div>
                    <div className="mb-2 flex flex-wrap md:flex-nowrap gap-4">
                        <div className="w-24 font-bold -mb-4">Email: </div>

                        <input type="text" className="rounded w-full border px-2 py-2" name="email" placeholder="Contact Email Id" onChange={this.inputChanged} />


                    </div>

                    <div className="mb-2 flex flex-wrap md:flex-nowrap gap-4">
                        <div className="w-24 font-bold -mb-4">Phone:</div>
                        <input type="text" className="rounded w-full border px-2 py-2" name="phone" placeholder="Phone or Mobile No." onChange={this.inputChanged} />
                    </div>
                    <div className="mb-2 flex flex-wrap md:flex-nowrap gap-4">
                        <div className="w-24 font-bold -mb-4">Query:</div>
                        <textarea type="text" className="rounded w-full border px-2 py-2" name="query" onChange={this.inputChanged} />
                    </div>
                    <div className="flex justify-center gap-4 mt-4 mb-4">
                        <input name="submit" type="submit" value="Submit" className="bg-gray-800 p-2 w-20 rounded text-lg text-white" onClick={this.submit} />
                        <input name="reset" type="reset" value="Reset" className="bg-gray-800 p-2 w-20 rounded text-lg text-white" />

                    </div>



                </div>

            </>
        );
    }

}